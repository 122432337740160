<template>
  <div class="container">
    <div class="select">
      <div class="mode">
        结算方式：
        <el-select v-model="wayValue" clearable placeholder="请选择">
          <el-option v-for="item in wayOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="state">
        状态：
        <el-select v-model="stateValue" clearable placeholder="请选择">
          <el-option
            v-for="item in stateOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="type">
        结算类型：
        <el-select v-model="typeValue" clearable placeholder="请选择">
          <el-option
            v-for="item in  typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        日期：
        <el-date-picker
          v-model="dateValue"
          type="datetimerange"
          range-separator="至"
          :default-time="['00:00:00', '23:59:59']"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
    </div>
    <div class="export">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="input" placeholder="结算单号/采购单号"></el-input>
      </div>
      <el-button type="primary" @click="getBalanceList('nopage')">查询</el-button>
      <el-button type="info" @click="balanceExport">导出</el-button>
    </div>
    <div class="table">
      <el-table @selection-change="handleSelectionChange" :cell-style="{ textAlign: 'center' }"   @sort-change="sortChange" :header-cell-style="{textAlign: 'center'}" border :data="balanceList" stripe style="width: 100%">
         <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="balance_sn" width="140" sortable label="结算单号"></el-table-column>
        <el-table-column prop="purchase_sn" label="采购单号"></el-table-column>
        <el-table-column prop="item_num" label="商品数量"></el-table-column>
        <el-table-column prop="total_price" width="140" sortable label="结算总额"></el-table-column>
        <el-table-column prop="bonus_points" label="优惠积分"></el-table-column>
        <el-table-column prop="real_price" label="实结算额"></el-table-column>
        <el-table-column prop="state_type" label="结算类型"></el-table-column>
        <el-table-column prop="state_way" label="结算方式"></el-table-column>
        <el-table-column prop="state_status" label="状态"></el-table-column>
        <el-table-column prop="bal_cert" label="结算凭证">
          <template slot-scope="scope">
            <el-popover placement="left"  width="400" trigger="click">
              <img  :src="scope.row.state_cert" style="width: 400px; heihgt: 400px"  class="enlarge" />
              <el-button slot="reference" style="border: 0">
                <img :src="scope.row.state_cert" class="head_pic" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="state_date" width="140" sortable label="结算时间"></el-table-column>
        <el-table-column prop="create_date" width="140" sortable label="创建时间"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getBalanceDetail(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @current-change="curretnChange"
        @prev-click="curretnChange"
        @size-change="handleSizeChange"
        :current-page="balanecPage"
        :hide-on-single-page="false"
        :page-sizes="[10, 20, 30, 40]"
        @next-click="curretnChange"
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog title="结算单明细" :close-on-press-escape="false" :visible.sync="dialogVisible" width="90%">
      <span>
        <el-table :cell-style="{ textAlign: 'center' }" @sort-change="sortDetailChange" :header-cell-style="{textAlign: 'center'}" :data="detailList" border style="width: 100%">
          <el-table-column type="index" label="编号"></el-table-column>
          <el-table-column prop="purchase_sn" sortable label="采购单号"></el-table-column>
          <!-- <el-table-column prop="item_id" label="商品ID"></el-table-column> -->
          <el-table-column prop="item_name" label="商品名称"></el-table-column>
          <el-table-column prop="item_code" label="商品条码"></el-table-column>
          <el-table-column prop="item_num" label="商品数量"></el-table-column>
          <el-table-column prop="item_price" label="单价"></el-table-column>
          <!-- <el-table-column prop="pay_price" label="结算金额" ></el-table-column> -->
          <el-table-column prop="bonus_points" label="优惠积分"></el-table-column>
          <el-table-column prop="real_price" sortable label="实际结算额"></el-table-column>
          <el-table-column prop="state_date" label="结算时间"></el-table-column>
          <el-table-column prop="create_date" label="创建时间"></el-table-column>
        </el-table>
      </span>
      <div class="pagination">
      <el-pagination
        background
        @current-change="curretnDetailChange"
        @prev-click="curretnDetailChange"
        :current-page="detailPage"
        :hide-on-single-page="false"
        @next-click="curretnDetailChange"
        layout="total,prev, pager, next"
        :total="detailTotal"
      ></el-pagination>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDateTime } from '@/utils/util'
export default {
  name: 'statement',
  data () {
    return {
      dialogVisible: false,
      input: '',
      stateValue: '',
      typeValue: '',
      order_by: '',
      detail_order_by: '',
      balanecPage: 1,
      detailPage: 1,
      detailLimitL: 10,
      limit: 10,
      wayValue: '',
      dateValue: [],
      balanceList: [],
      stateOptions: [],
      multipleSelection: [],
      wayOptions: [
        {
          value: '1',
          label: '线上支付'
        },
        {
          value: '2',
          label: '线下支付'
        }
      ],
      total: 0,
      detailTotal: 0,
      typeOptions: [
        {
          value: '1',
          label: '现结'
        },
        {
          value: '2',
          label: '月结'
        }
      ],
      detailList: []
    }
  },
  created () {
    this.getBalanceList()
    this.getStateStatus()
    this.getStateWay()
    this.getStateType()
  },
  computed: {
    shopInfo () {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    }
  },
  methods: {
    handleSelectionChange (val) {
      // 多选值改变
      this.multipleSelection = val
    },
    sortChange (e) { // 外层排序方式
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.order_by = orderBy + '__desc'
      } else {
        this.order_by = orderBy + '__asc'
      }
      this.getBalanceList('nopage')
    },
    sortDetailChange (e) { // 外层排序方式
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.detail_order_by = orderBy + '__desc'
      } else {
        this.detail_order_by = orderBy + '__asc'
      }
      this.getBalanceDetail('nopage')
    },
    getStateWay () {
      // 获取结算单结算方式
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      this.$http
        .post('v1/state/state_way', { shop_id })
        .then(res => {
          this.wayOptions = res.data.data
        })
        .catch(err => {
          return this.$messgae.error(err)
        })
    },
    getStateType () {
      // 获取结算单类型
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      this.$http
        .post('v1/state/state_mode', { shop_id })
        .then(res => {
          this.typeOptions = res.data.data
        })
        .catch(err => {
          return this.$messgae.error(err)
        })
    },
    handleSizeChange (e) {
      this.limit = e
      this.getBalanceList()
    },
    getStateStatus () {
      // 获取结算单状态列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      this.$http
        .post('v1/state/status', { shop_id })
        .then(res => {
          this.stateOptions = res.data.data
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    getBalanceDetail (e) {
      // 获取结算单明细
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      e = e === 'nopage' ? this.currentStateDetail : e
      this.currentStateDetail = e
      // eslint-disable-next-line camelcase
      var state_id = this.currentStateDetail.balance_id
      this.detailPage = e === 'nopage' ? 1 : this.detailPage
      var page = this.detailPage
      // eslint-disable-next-line camelcase
      var order_by = this.detail_order_by
      var limit = this.detailLimitL
      this.$http
        .post('/v1/state/item_lst', { shop_id, order_by, state_id, page, limit })
        .then(res => {
          this.dialogVisible = true
          this.detailList = res.data.data.list
          this.detailTotal = res.data.data.total
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    curretnChange (e) {
      // 页码切换
      this.balanecPage = e
      this.getBalanceList()
    },
    curretnDetailChange (e) { // 详情页码切换
      this.detailPage = e
      this.getBalanceDetail()
    },
    balanceExport () {
      // 结算单导出
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      var keyword = this.input
      // eslint-disable-next-line camelcase
      var state_mode = this.wayValue
      // eslint-disable-next-line camelcase
      var status = this.stateValue
      // eslint-disable-next-line camelcase
      var state_type = this.typeValue

      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (Object.prototype.toString.call(this.dateValue) === '[object Array]') {
        if (this.dateValue[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.dateValue[0])
        }
        if (this.dateValue[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.dateValue[1])
        }
      }
      var multipleSelection = this.multipleSelection
      // eslint-disable-next-line camelcase
      var ids = []
      if (multipleSelection.length > 0) {
        multipleSelection.forEach(ele => {
          ids.push(ele.balance_id)
        })
      }
      // eslint-disable-next-line camelcase
      ids = ids.join(',')
      this.$http
        .post('/v1/state/export', {
          keyword,
          shop_id,
          status,
          state_type,
          ids,
          state_mode,
          start_date,
          end_date
        })
        .then(res => {
          this.$message.success(res.data.msg)
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    getBalanceList (e) {
      // 获取结算单列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      var keyword = this.input
      // eslint-disable-next-line camelcase
      var state_mode = this.wayValue
      // eslint-disable-next-line camelcase
      var status = this.stateValue
      // eslint-disable-next-line camelcase
      var order_by = this.order_by
      // eslint-disable-next-line camelcase
      var state_type = this.typeValue
      this.balanecPage = e === 'nopage' ? 1 : this.balanecPage
      var page = this.balanecPage
      var limit = this.limit
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      if (Object.prototype.toString.call(this.dateValue) === '[object Array]') {
        if (this.dateValue[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.dateValue[0])
        }
        if (this.dateValue[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.dateValue[1])
        }
      }
      this.$http
        .post('/v1/state/lst', {
          shop_id,
          keyword,
          state_type,
          page,
          limit,
          order_by,
          start_date,
          end_date,
          status,
          state_mode
        })
        .then(res => {
          this.balanceList = res.data.data.list
          this.total = res.data.data.total
        })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    .el-date-editor {
      width: 300px;
    }
  }
  .enlarge {
    height: 400px;
    width: 400px;
  }
  .export {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .input {
      display: flex;
      align-items: center;
      div {
        width: 100px;
        font-size: 18px;
      }
      .el-input {
        width: 300px;
        font-size: 14px;
      }
    }
    .el-button {
      margin-left: 40px;
    }
  }
  .table {
    margin-top: 30px;
    td {
      .cell {
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    margin-bottom: 20px;
    align-items: center;
  }
}
</style>
